import React from 'react';
import Hero from '../Components/Section1-Main';
import Badges from '../Components/Section-Cards';
import CarouselComponent from '../Components/Carousel.jsx';
import '../index.css';
import FAQComponent from '../Components/FAQ';
import OpeningTimes from '../Components/2-col-section-OP';
import ServiceBox from '../Components/serviceList.jsx';
import BlogSlider from '../Components/BlogSlider.jsx';
import { Suspense } from 'react';


function MainPage() {

    return (

        <div>
            <head>
                <title>Professional Podiatry Care, Foot Treatment & Specialist Services | Expert Manchester Podiatry Clinic for All Your Foot Health Needs</title>                
                <meta name="description" content="Professional podiatry services including foot pain treatment, diabetic foot care, orthotics, and nail surgery. Book your appointment today with our experienced podiatrists." />
                <meta name="keywords" content="podiatry clinic, foot care, podiatrist, foot pain, orthotics, diabetic foot care, nail surgery, foot treatment" />
                <meta name="robots" content="index, follow" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </head>
            <Suspense fallback={<div>Loading...</div>}>
            <h1 className="sr-only">Droshna The Podiatrist</h1>

                <Hero />
                <Badges />
                <ServiceBox />
                <CarouselComponent />
                <OpeningTimes />
                <FAQComponent />
                <BlogSlider />
            </Suspense>
        </div>
    );
}
export default MainPage;