import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Components/header.jsx';
import MainPage from './pages/MainPage.jsx';
import AboutPage from './pages/about.jsx';
import Footer from './Components/footer.jsx';
import ServicePage from './pages/services.jsx';
import ConForm from './pages/ConsultationForm.jsx';
import CookieConsent from'./Components/CookiesConsent.jsx';
import NotFoundPage from './pages/NotFoundPage';
import OrthoticsPage from './pages/Orthotics.jsx';
import Blog from './pages/BlogGrid.jsx';
import BlogPost from './Components/BlogPost.jsx';
import BlogEditor from './pages/BlogEditor'


 // eslint-disable-next-line
function App() {
 
 
  return (
    <Router basename="/">
      <div className="flex flex-col min-h-screen">
        <Header />
        <div className="flex-grow mt-16">
          <Routes>
            <Route exact path="/" element={<MainPage />} />
            <Route exact path="/about" element={<AboutPage />} />
            <Route exact path="/services" element={<ServicePage />} />
            <Route exact path="/ConsultationForm" element={<ConForm/>}/>
            <Route exact path="/Orthotics" element={<OrthoticsPage />} />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/editor" element={<BlogEditor />} />
            <Route exact path="/blogs/:id" element={<BlogPost />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
        <CookieConsent />
        <Footer/>
      </div>
  
    </Router>
    
  );
 // eslint-disable-next-line
}export default App;